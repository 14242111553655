<script setup lang="ts">
const localePath = useLocalePath();
const { formatLink } = useInternationalization(localePath);
const { t } = useI18n();
</script>

<template>
  <div class="my-10 custom-container">
    <div class="grid grid-cols-1 md:grid-cols-2">
      <div class="lg:w-lg">
        <h1 class="font-700 c-scheppach-primary-700 text-[35px] mb-6">
          {{ t("pageNotFound.header") }}
        </h1>
        <p>
          {{ t("pageNotFound.text") }}
        </p>
        <NuxtLink
          class="block mt-6 c-scheppach-primary-500 font-700 underline underline-offset-5"
          :to="formatLink('/')"
          >{{ t("goBackHome") }}</NuxtLink
        >
      </div>
      <div class="mt-15 md:mt-0 relative flex items-center justify-center">
        <img
          class="absolute max-w-xl max-h-xl mt-80"
          src="../../assets/maintenance_mode.svg"
        />
      </div>
    </div>
  </div>
</template>
